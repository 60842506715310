$(document).ready(function () {
    searchMain()
    initSliderHistory();
    dropMenu();
    burgerMenu();
    SliderMain();
    SliderCounter();
    Select2();
    DatePublish();
    datepickerInit()
    AnimatedText();
    changeUrlOnScroll();
    MobileVersion()
    initGallery();
    Popup();
    SendForm();
    SendGlobalFilter();
});

$(window).on('load', function () {

});
function Popup(){

    $(document).on('click','.filter-big', function () {
        $('.modal').addClass('show');
        $('body').addClass('no-scroll');
        $('.sidebar form').appendTo('.modal__wrapper');

    })
    $(document).on('click','.modal-bg',function () {
        $('.modal').removeClass('show');
        $('body').removeClass('no-scroll');
    });
}
function datepickerInit () {
    $("#datepicker1").datepicker({
        showOn: "button",
        buttonImage: "static/app/img/calendar.svg",
        altField: "#alternate",
        altFormat: "DD, d.MM",
        autoSize: true,
        maxDate: 0,
        changeMonth: true,
        changeYear: true,

        onSelect: function (dateText) {
           $('#datepicker1').css('background','#E2F3EF');
           $('#datepicker1').css('border','1px solid #465558')
            // let selectedDate = Number(dateText.substr(6, 4)) + '/' + Number(dateText.substr(3, 2)) + '/' +  Number(dateText.substr(0, 2))+ '/';
            // window.location.pathname = selectedDate;
        },
    });
    $("#datepicker2").datepicker({
        showOn: "button",
        buttonImage: "static/app/img/calendar.svg",
        altField: "#alternate",
        altFormat: "DD, d.MM",
        autoSize: true,
        maxDate: 0,
        changeMonth: true,
        changeYear: true,

        onSelect: function (dateText) {
            $('#datepicker2').css('background','#E2F3EF')
            $('#datepicker2').css('border','1px solid #465558')
            // let selectedDate = Number(dateText.substr(6, 4)) + '/' + Number(dateText.substr(3, 2)) + '/' +  Number(dateText.substr(0, 2))+ '/';
            // window.location.pathname = selectedDate;
        },
    });
}
function DatePublish(){
    $(document).on('click','.date-publish-button',function () {
        $('.date-publish-button').removeClass('active');
        $(this).addClass('active');
    });
    $(document).on('click','.date-publish',function () {
        $('.select2-container .select2-dropdown').addClass('mtop');
    });
}
function changeUrlOnScroll(){
    let flag = 0;
    function elem_in_visible_area(selector) {
        let elem_p = selector,
            elem_p_height = elem_p.height(),
            offset_top_el = elem_p.offset().top,
            offset_bottom_el = offset_top_el + elem_p.height(),
            scrolled = $(window).scrollTop(),
            scrolled_bottom = scrolled + $(window).height();
        if (scrolled_bottom > offset_top_el && offset_bottom_el > scrolled) {
            return true;
        }
        return false;
    }

    $(window).scroll(function() {
        if($('.count').length > 0){
            if (elem_in_visible_area($('.count')) && flag === 0){
                CounterNumber();
                flag = 1;
            }
        }
    })
}
function CounterNumber(){
    $('.count > p').each(function () {
        $(this).prop('Counter',0).animate({
            Counter: $(this).text()
        }, {
            duration: 4000,
            easing: 'swing',
            step: function (now) {
                $(this).text(Math.ceil(now));
            }
        });
    });
}
function AnimatedText(){
    var words = ['свою'],
        part,
        i = 0,
        offset = 0,
        len = words.length,
        forwards = true,
        skip_count = 0,
        skip_delay = 15,
        speed = 500;

    var wordflick = function () {
        setInterval(function () {
            if (forwards) {
                if (offset >= words[i].length) {
                    ++skip_count;
                    if (skip_count == skip_delay) {
                        forwards = false;
                        skip_count = 0;
                    }
                }
            }
            else {
                if (offset == 0) {
                    $('.word').removeClass('bg-change-animate')
                    forwards = true;
                    i++;
                    offset = 0;
                    if (i >= len) {
                        i = 0;
                    }
                }
            }
            part = words[i].substr(0, offset);
            if (skip_count == 0) {
                if (forwards) {
                    offset++;
                }
                else {
                    // setTimeout()
                    $('.word').addClass('bg-change-animate')

                    offset=0;
                }
            }
            $('.word').text(part);
        },speed);
    };
    wordflick();
}
function initSlider1 (slider) {
    const swipers = $(`.${slider}`);
    console.log(swipers, 32332)
    swipers.each(function () {
        const ths = $(this);
        const id = ths.attr('id');
        console.log(id)
        new Swiper(`.${id}`, {
            slidesPerView: 1.2,
            watchOverflow: true,
            spaceBetween: 12,
            speed: 300,
            slidesPerGroup: 1,
            pagination: {
                el: `.${id}__swiper-pagination`,
            },
        });

    });
}

function initSliderHistory () {
    new Swiper('#slider-history', {
            slidesPerView: 1,
            watchOverflow: true,
            spaceBetween: 20,
            speed: 300,
            slidesPerGroup: 1,
            pagination: {
                el: `.slider-history__swiper-pagination`,
            },
        navigation: {
            nextEl: ".swiper-button-prev-main-mobile",
            prevEl: ".swiper-button-next-main-mobile",
        },
    });
    new Swiper('#slider-history2', {
        slidesPerView: 1,
        watchOverflow: true,
        spaceBetween: 20,
        speed: 300,
        slidesPerGroup: 1,
        pagination: {
            el: `.slider-history__swiper-pagination`,
        },
        navigation: {
            nextEl: ".swiper-button-prev-main-mobile",
            prevEl: ".swiper-button-next-main-mobile",
        },
    });
}
function initGallery() {
    var swiper = new Swiper(".base-damages-gallery", {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
        navigation: {
            nextEl: ".swiper-button-next-gallery",
            prevEl: ".swiper-button-prev-gallery",
        },
    });
    var swiper2 = new Swiper(".base-damages-slider", {
        spaceBetween: 10,
        navigation: {
            nextEl: ".swiper-button-next-damages1",
            prevEl: ".swiper-button-prev-damages1",
        },
        thumbs: {
            swiper: swiper,
        },
    });
    $(".base-damages-gallery").closest('.swiper').find('.swiper-button-prev-gallery').hide();
    $(".base-damages-gallery").closest('.swiper').find('.swiper-button-next-gallery').hide();
    $(".base-damages-gallery").hover(
        function(){
            $(".base-damages-gallery").closest('.swiper').find('.swiper-button-prev-gallery').show();
            $(".base-damages-gallery").closest('.swiper').find('.swiper-button-next-gallery').show();
        },
        function(){
            $(".base-damages-gallery").closest('.swiper').find('.swiper-button-prev-gallery').hide();
            $(".base-damages-gallery").closest('.swiper').find('.swiper-button-next-gallery').hide();
        });
}
function Select2(){
    $('.select2-base-damages').select2();

    $('.select2-data-publich').select2({
        minimumResultsForSearch: Infinity
    });
    $('.select2-data-publich').on('select2:select', function (e) {
       $(this).closest('.date-publish').find('.select2.select2-container .select2-selection').addClass('border-click')
    });
    $(document).on('click','.select2-selection',function (){
        if($('.select2-search__field').length > 0)$('.select2-search__field')[0].focus();
    })
    $(document).on('change','.select2-base-damages',function (){
        $(this).closest('.base-select').find('.select2-container--default .select2-selection--single').css('background-color','#E2F3EF')
        $(this).closest('.base-select').find('.select2-container--default .select2-selection--single').css('border','1px solid #465558')
        $(this).closest('.base-build-damages-find__wrapper').find('.select2-container--default .select2-selection--single').css('background-color','#E2F3EF')
        $(this).closest('.base-build-damages-find__wrapper').find('.select2-container--default .select2-selection--single').css('border','1px solid #465558')
    })

    $(document).on('click','.button-clear-all',function (){
        $('.select2-base-damages').val('').trigger('change');
        $('#datepicker1').val('');
        $('#datepicker2').val('');
        $('.base-select').find('.select2-container--default .select2-selection--single').css('background-color','#fff')
        $('.base-select').find('.select2-container--default .select2-selection--single').css('border','1px solid #DEE3E7')
        $('#datepicker2').css('background','#fff')
        $('#datepicker2').css('border','1px solid #DEE3E7')
        $('#datepicker1').css('background','#fff')
        $('#datepicker1').css('border','1px solid #DEE3E7')
    })
    $(document).on('click','.button-base-dameges',function () {
       $('.filter-big').addClass('filter-active')
    })

};

function findObjectsBaseDamage(){
    $('.count-posts > h5').text('0');
    $('.count-posts').addClass('not-find');
    $('.date-publish').addClass('date-publish-disable');
}
function searchMain() {
    $('.close-button-search').hide();

    $(document).on('click','#search-main',function () {
        $('.header-social').hide();
        $('.close-button-search').show();
        $('.header-search').addClass('width-search')
    })
    $(document).on('click','.close-button-search',function () {
        $('#search-main').val('');
        $('.close-button-search').hide();
    })
    if ($(window).width() > 666) {
        $('#search-main').focusout(function () {
            $('.header-social').show();
            $('.header-search').removeClass('width-search')
        })
    } else {
        $(document).on('click','.header-mobile-search',function () {
            $('.header__burger-mobile').hide();
            $('.header-logo').hide();
            $('.header-profile').hide();
            $('.header-search').css('display', 'flex');
            $('.header__wrapper-search').css('width','100%');
        })
        $('#search-main').focusout(function () {
            $('.header__burger-mobile').show();
            $('.header-logo').show();
            $('.header-profile').show();
            $('.header-search').css('display', 'none');
            $('.header__wrapper-search').css('width','unset');
        })
    }
}

function dropMenu() {
        $('.header-dropdown').click(function() {
            $('.header-dropdown-list').show();
            $(this).addClass('header-dropdown-open')
        });
        $(document).click(function(event) {
            var target = $(event.target);

            if (target[0]!==$('.header-dropdown')[0] && target[0]!==$('.header-dropdown > h3')[0] && target[0]!==$('.header-dropdown > svg')[0]) {
                $('.header-dropdown-list').hide();
                $('.header-dropdown').removeClass('header-dropdown-open')
            }
        });
    $(document).click(function(event) {
        var target = $(event.target);

        if(target[0]==$('.header-menu-mobile.open')[0] && target[0]!==$('.header-burger > img')[0]){
            $('.header-menu-mobile').removeClass('open');
            $('body').removeClass('hidden-scroll');
        }
    })
}

function burgerMenu() {
    $(document).on('click','.header-burger',function () {
        $('.header-menu-mobile').addClass('open')
        $('body').addClass('hidden-scroll')
    })
    $(document).on('click','.close-mobile-burger',function () {
        $('.header-menu-mobile').removeClass('open')
        $('body').removeClass('hidden-scroll')
    })

}

function SliderMain() {
    var $swiper = $(".swiper-container");
    var $bottomSlide = null;
    var $bottomSlideContent = null;
    var mySwiper = new Swiper(".swiper-container", {
        spaceBetween: 1,
        slidesPerView: 5,
        centeredSlides: true,
        speed: 600,
        roundLengths: true,
        loop: true,
        allowTouchMove:false,
        loopAdditionalSlides: 30,
        pagination: {
            el: ".swiper-pagination",
        },
        navigation: {
            nextEl: ".swiper-button-next-main",
            prevEl: ".swiper-button-prev-main"
        },
        on: {
            slideChange: function () {
                var totalSlides = this.slides.length / 3;
                var activeIndex = this.activeIndex - totalSlides + 1;
                if(activeIndex > totalSlides)activeIndex=1;
                if(activeIndex <= 0)activeIndex=totalSlides;

                $('.swiper-counter > h2').text('0' + activeIndex);
                $('.swiper-counter > h4').text('/ 0' + totalSlides);
                },
        },
    });
    if(window.innerWidth <= 666){
        var swip = new Swiper(".main-mobile-slider", {
            spaceBetween: 10,
            slidesPerView: 1,
            navigation: {
                nextEl: ".swiper-button-prev-main-mobile",
                prevEl: ".swiper-button-next-main-mobile",
            },
        });
    }

}
function SliderCounter() {
    let counter = 1;
    let length = $('.main-swiper-slide').length / 3;
    $('.swiper-counter > h4').text('/0' + length);
    console.log(length);
    $(document).on('click','.swiper-button-prev-main',function (){
        if(counter-1 <= 0)counter=length;
        else if(counter <= length)counter--;
        $('.swiper-counter > h2').text('0' + counter);
        console.log(counter)
    })
}
function MobileVersion(){
    if(window.innerWidth < 666){
        initSlider1('slider-history');
    }
    else{
        $(document).on('click','.date-publish',function () {
            $('.select2-results').addClass('filter-font');
        });
    }
    if($('.history').length > 0){
        $('#ui-datepicker-div').addClass('history-Picker')
    }
    if($('.base-damages').length > 0){
        $('#ui-datepicker-div').addClass('base-damages-Picker')
    }
}
let currentPage;
let searchTimeout;
function SendForm(){
    $(document).on('click','.button-base-dameges',function () {
        let form = $('#base-dameges');
        currentPage = $('input[name=page]');
        let data = form.serialize();
        console.log(data)
        ajaxFindObjects(data);
    })
    $(document).on('input', '#search-main', function (){
        onChangeSearch();
    });
    $(document).on('click','.main-search-filter__one',function () {
        // по дата антибуам робити пошук
    })


}
function SendGlobalFilter(){
    $(document).on('click','.main-search-filter__one', function (){
        $('.main-search-filter__one').removeClass('active');
        $(this).addClass('active');
        let filter = $(this).data('name');
        let obj = {filter, search}
        ajaxSearchFilter(obj);
    })
}
let search;
function onChangeSearch () {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(function () {
        search = $('#search-main').val();
        let obj = {search};
        ajaxGlobalSearch(obj);
    }, 3000);
}
function setCookie(cname, cvalue) {
    document.cookie = cname + "=" + cvalue;
}
function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}