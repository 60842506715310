/* ============================ apiServer START ================================================================= */
console.log('apiServer');
let ApiConst = 'http://localhost:8080/wp-admin/admin-ajax.php?action=';
const API = {
   findObjects: ApiConst + 'filter',
   globalSearch: ApiConst + 'global_search',
   searchFilter: ApiConst + 'search_filter',
};

function ajaxFindObjects (date) {
   $.ajax({
      url: API.findObjects,
      dataType: "json",
      data: date,
      method: 'POST',
      success: function (data) {
         console.log('data', data);
      },
      error: function (error) {
         console.log(error);
         findObjectsBaseDamage();
      },
      complete: function () {
      },
   });
}

function ajaxGlobalSearch (date) {
   $.ajax({
      url: API.globalSearch,
      dataType: "json",
      data: date,
      method: 'POST',
      success: function (data) {
         console.log('data', data);
         $('section').remove();
         data.html.insertAfter("header");
      },
      error: function (error) {

         console.log(error);
      },
      complete: function () {
      },
   });
}
function ajaxSearchFilter (date) {
   $.ajax({
      url: API.searchFilter,
      dataType: "json",
      data: date,
      method: 'POST',
      success: function (data) {
         console.log('data', data);
      },
      error: function (error) {

         console.log(error);
      },
      complete: function () {
      },
   });
}

/* ============================ apiServer END ================================================================= */
