"use strict";
/* ============================ refs START ================================================================= */
const refs = Object.freeze({
   dropDownList: $('.dropdown__list'),
   dropDownBtn: $('.dropdown__button'),
   dropDownItems: $('.dropdown__item'),
   dropDownInput: $('.dropdown__input-hidden'),
   footer: $('footer'),
   body: $('body'),
   menuContainer: $('.menu__container'),
   headerLogo: $('.header__logo'),
   buttonToApply: $('[data-to-apply]'),
   buttonToApplyFilterPopup: $('[data-filter-to-apply]'),
   rubricListItem: $('.rubric-list__item'),
   hashtagListItem: $('.hashtag-list__item'),
   rubricFilterButton: $('.rubric-filter__button'),
   hashtagFilterButton: $('.hashtag-filter__button'),
   filterButtonPopup: $('[data-filter-button-popup]'),
   filter: $('.filter'),
});

/* ============================ refs END ================================================================= */
